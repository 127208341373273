<template>
  <!-- current stage chart -->
  <div class="stage_container">
    <div class="stage_num" :class="{ active: isActive1 }">1
      <h4 class="stage_title txt-black">填寫申請表</h4>
    </div>
    <hr>
    <div class="stage_num" :class="{ active: isActive2 }">2
      <h4 class="stage_title txt-black">申請資料審核</h4>
      <small
        class="stage_search"
        :class="{ active: isActive1 }"
        @click="toggleVerifyModal"
      >
        審核狀態查詢
      </small>
    </div>
    <hr>
    <div class="stage_num" :class="{ active: isActive3 }">3
      <h4 class="stage_title txt-black">繳納入會費用</h4>
    </div>
    <hr>
    <div class="stage_num" :class="{ active: isActive4 }">4
      <h4 class="stage_title txt-black">入會成功</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationStage',
  data() {
    return {
      isActive1: false,
      isActive2: false,
      isActive3: false,
      isActive4: false,
    };
  },
  created() {
    // console.log('$route:', this.$route.path);
    switch (true) {
      case this.$route.path.includes('index'):
        this.isActive1 = true;
        this.isActive2 = false;
        this.isActive3 = false;
        this.isActive4 = false;
        break;
      case this.$route.path.includes('verify'):
        this.isActive1 = false;
        this.isActive2 = true;
        this.isActive3 = false;
        this.isActive4 = false;
        break;
      case this.$route.path.includes('payment'):
        this.isActive1 = false;
        this.isActive2 = false;
        this.isActive3 = true;
        this.isActive4 = false;
        break;
      case this.$route.path.includes('success'):
        this.isActive1 = false;
        this.isActive2 = false;
        this.isActive3 = false;
        this.isActive4 = true;
        break;
      default:
        break;
    }
  },
  methods: {
    toggleVerifyModal() {
      this.$emit('toggleVerifyModal');
    },
  },
};
</script>
