<template>
  <div class="err_message_box">
    <label v-show="item.label" :for="id" class="p">
      <span class="txt-red txt-regular" v-if="item.required">*</span>
      {{item.label}}
    </label>
    <span class="err_message txt-red small">{{errorMsg}}</span>
    <select
      :id="id"
      :value="value"
      :rules="rules"
      :type="type"
      :error="error"
      @blur="test()"
      @change="$emit('input', $event.target.value)">
      <template v-if="item.placeHolder">
        <option value="" disabled>{{ item.placeHolder }}</option>
      </template>
      <template v-else>
        <option value="" disabled>請選擇</option>
      </template>
      <option v-for="(option, index) in item.options" :key="`option_${index}`"
        :value="option">{{option}}</option>
    </select>
  </div>
</template>
<script>
export default {
  name: 'FormSelect',
  props: {
    value: {},
    type: {
      type: String,
      default: 'text',
    },
    rules: Array,
    // label: String,
    placeholder: String,
    item: {
      placeholder: String,
      title: String,
    },
  },
  data() {
    return {
      errorMsg: '',
      id: null,
    };
  },
  watch: {
    value(v) {
      this.errorMsg = this.validate(v) ? this.validate(v) : '';
    },
  },
  computed: {
    hasRules() {
      return this.rules !== undefined
        && this.rules !== null
        && this.rules.length > 0;
    },
    error() {
      if (this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  methods: {
    test() {
      this.errorMsg = this.validate(this.value) ? this.validate(this.value) : '';
    },
    validate(val = this.value) {
      if (this.hasRules === true) {
        const errorMessage = [];
        this.rules.forEach((rule) => {
          if (typeof rule === 'function') {
            if (rule(val) !== true) {
              errorMessage.push(rule(val));
            }
          } else if (typeof rule === 'object') {
            if (!val.match(rule[0])) {
              errorMessage.push(rule[1]);
            }
          }
        });
        if (errorMessage.length) {
          return errorMessage[0];
        }
      }
      return '';
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid;
  },
};
</script>
